import React from "react";
import Link from "next/link";
import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import styles from "./styles.module.scss";
import {
  Container,
  Row,
  Col
} from 'reactstrap';
/**
 *
 * @param global
 * @returns {JSX.Element}
 * @constructor
 */
export default function FourZeroFour({ global }) {
  return (
    <section id="FourZeroFour" className={styles.FourZeroFour}>
      <Container>
        <article className="d-flex flex-column align-items-center text-center">
          <SourceFlowText global={global} path="four_oh_four.header" type="html">
            <h1 className="d-flex flex-column mb-5">
              <span className="wrapper">404</span>
              <span>Page Not Found</span>
            </h1>
          </SourceFlowText>
          <aside>
            <SourceFlowText
              global={global}
              path="four_oh_four.content"
              type="html"
            >
              <p>
                This is a 404 error, which means you've clicked on a bad link or
                entered an invalid URL.
              </p>
            </SourceFlowText>
            <Link href="/" passHref legacyBehavior>
              <a className="button tertiaryBtn">Go back home</a>
            </Link>
          </aside>
        </article>
      </Container>
    </section>
  );
}

import React from "react";
import { Global } from "@sourceflow-uk/sourceflow-sdk";
import { SourceFlowHead } from "@sourceflow-uk/sourceflow-head";
import metaObject from "@/.sourceflow/metadata.json";
import FourZeroFour from "../components/404";

/**
 *
 * @param global
 * @param meta
 * @returns {JSX.Element}
 * @constructor
 */
export default function FourZeroFourPage({ global, meta }) {
  return (
    <>
      <SourceFlowHead metaObject={metaObject} title={meta.title} />
      <div className="bg-light-white">
        <FourZeroFour global={global} />
      </div>
    </>
  );
}

/**
 *
 * @returns {Promise<{props: {meta: {title: string}, global}}>}
 */
export async function getStaticProps() {
  const global = (await Global()).toJson();

  return {
    props: {
      global,
      meta: {
        title: "404 - This page is not found",
      },
    },
  };
}

// -- Reference -- //
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflow-head
